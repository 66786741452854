<template>
  <div>
    <form-create
      :is-add-new-sidebar-active.sync="isAddNewSidebarActive"
      :lista-usuarios="listaUsuarios"
      titulo="Novo Perfil"
      @refetch-data="refetchData"
    />

    <b-card
      no-body
      class="mb-0"
    >
      <h5 class="card-title pt-2 pl-2 pb-0 mb-0">
        Lista de Perfis
      </h5>
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Exibição</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Pesquisar..."
              />
              <b-button
                variant="success"
                @click="isAddNewSidebarActive = true"
              >
                <span class="text-nowrap">
                  Adicionar
                </span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="fetchDados"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="Nenhum resultado encontrado"
        :sort-desc.sync="isSortDirDesc"
        :busy="isLoad"
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle" />
            <strong> Carregando...</strong>
          </div>
        </template>

        <template #cell(permissions)="data">
          <b-media
            vertical-align="center"
            align="center"
          >

            {{ data.item.permissions.length }}
          </b-media>
        </template>

        <template #cell(users)="data">
          <b-media vertical-align="center">
            <div
              v-for="(item, index) in data.item.users"
              :key="index"
            >
              {{ item.name }}
            </div>
          </b-media>
        </template>

        <!-- Column: Actions -->
        <template #cell(ações)="data">

          <router-link :to="{ name: 'editar-perfil', params: { name: data.item.name } }">

            <feather-icon
              icon="EditIcon"
              size="16"
            />

          </router-link>

        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Exibindo {{ dataMeta.from }} a {{ dataMeta.to }} dos {{ dataMeta.of }} resultados
              encontrados</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalItens"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
      <modalConfirmacao
        id="modal-confirmacao"
        titulo="Excluir Item"
        :item-id="dadosItem.uuid"
        :item-nome="dadosItem.nome"
        @itemConfirmado="excluirItem"
        @click="modalExcluir(data.item)"
      />

      <b-modal
        id="modal-editar"
        v-model="modalShow"
        :title="`Editar ${dadosItem.name}`"
        :no-close-on-backdrop="true"
      >
        <validation-provider
          #default="validationContext"
          name="Nome do Perfil"
          rules="required"
        >
          <b-form-group
            label="Nome do Perfil"
            label-for="nome-subdisciplina"
          >
            <b-form-input
              id="nome-perfil"
              v-model="dadosItem.name"
              name="subdisciplina"
              autofocus
              :state="getValidationState(validationContext)"
              trim
              placeholder="Informe o nome do perfil"
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>

          </b-form-group>
        </validation-provider>

        <validation-provider
          #default="validationContext"
          name="Usuários"
          rules="required"
        >
          <b-form-group
            label="Escolha os Usuários"
            label-for="usuarios"
            :state="getValidationState(validationContext)"
          >
            <v-select
              v-model="dadosItem.users"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="listaUsuarios"
              label="name"
              :reduce="val => val.uuid"
              :clearable="false"
              input-id="usuarios"
              placeholder="Escolha os usuários"
              multiple
            />

            <b-form-invalid-feedback :state="getValidationState(validationContext)">
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <v-select
          v-model="dadosItem.permissions"
          :options="pagedOptions"
          label="name"
          :pagination="pagination"
          placeholder="Escolha as permissões"
          multiple
          @search="onSearch"
        >
          <template v-slot:no-options>
            Nenhuma opção encontrada
          </template>
        </v-select>
        <div class="d-flex justify-content-center">
          <b-pagination
            v-model="currentPagePermissions"
            class="mt-1"
            :total-rows="totalOptions"
            :per-page="perPagePermissions"
          />

        </div>

        <template #modal-footer>
          <div class="w-100">

            <b-button
              variant="primary"
              size="md"
              class="float-left"
              @click="modalShow = false"
            >
              Fechar
            </b-button>
            <b-button
              variant="success"
              size="md"
              class="float-right"
              @click="salvarItemEditado(dadosItem)"
            >
              Salvar
            </b-button>
          </div>
        </template>
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BLink,
  BMedia,
  BPagination,
  BRow,
  BSpinner,
  BTable,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

import store from '@/store'
import { onUnmounted, ref } from '@vue/composition-api'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import modalConfirmacao from '@/views/components/componentesPensou/ModalConfirmacao.vue'
import FormCreate from './FormCreate.vue'
import useItemList from './useItemList'
import perfisStoreModule from '../perfisStoreModule'

export default {
  components: {
    FormCreate,
    modalConfirmacao,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSpinner,
    BFormGroup,
    BFormInvalidFeedback,
    vSelect,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },

  setup() {
    const blankUserData = {
      name: '',
      users: [],
      permissions: [],
    }
    const PERFIS_APP_STORE_MODULE_NAME = 'app-perfis'

    // Register module
    if (!store.hasModule(PERFIS_APP_STORE_MODULE_NAME)) store.registerModule(PERFIS_APP_STORE_MODULE_NAME, perfisStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PERFIS_APP_STORE_MODULE_NAME)) store.unregisterModule(PERFIS_APP_STORE_MODULE_NAME)
    })

    const itemData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetItemData = () => {
      itemData.value = JSON.parse(JSON.stringify(blankUserData))
    }

    const isAddNewSidebarActive = ref(false)

    const perPagePermissions = ref(10)
    const totalItensPermissions = ref(0)
    const currentPagePermissions = ref(1)
    const perPageOptionsPermissions = [10, 25, 50, 100]
    const searchQueryPermissions = ref('')
    const sortByPermissions = ref('id')
    const isSortDirDescPermissions = ref(true)

    const {
      fetchDados,
      listaUsuarios,
      tableColumns,
      perPage,
      currentPage,
      totalItens,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      deleteItem,
      update,

    } = useItemList()

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetItemData)

    return {

      isAddNewSidebarActive,
      listaUsuarios,
      deleteItem,
      update,
      fetchDados,
      tableColumns,
      perPage,
      currentPage,
      totalItens,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      refFormObserver,
      getValidationState,
      resetForm,
      itemData,
      perPagePermissions,
      totalItensPermissions,
      currentPagePermissions,
      perPageOptionsPermissions,
      searchQueryPermissions,
      sortByPermissions,
      isSortDirDescPermissions,
    }
  },
  data() {
    return {
      options: [],
      currentPage: 1,
      pageSize: 10,
      selectedOption: null,
      searchOptions: '',
      totalOptions: 0,

      dadosItem: {
        uuid: '',
        name: '',
        users: [],
        permissions: [],
      },
      modalShow: false,

    }
  },
  computed: {
    isLoad() {
      return this.$store.state['app-perfis'].load
    },
    pagedOptions() {
      const startIndex = (this.currentPage - 1) * this.pageSize
      const endIndex = startIndex + this.pageSize
      return this.options.slice(startIndex, endIndex)
    },
    pagination() {
      return {
        enabled: true,
        dropdown: true,
        perPage: this.pageSize,
      }
    },
  },

  watch: {
    currentPagePermissions() {
      this.fetchOptions()
    },
    searchOptions() {
      this.fetchOptions()
    },
  },
  mounted() {
    this.fetchOptions()
  },

  methods: {
    onSearch(text) {
      this.searchOptions = text
    },
    editarItem(item) {
      this.modalShow = true
      this.dadosItem.name = item.name

      this.dadosItem.users = item.users.map(item => item.uuid)
      this.dadosItem.permissions = item.permissions
    },

    fetchOptions() {
      store.dispatch('app-perfis/fetchPermissoes', {
        q: this.searchOptions,
        perPage: this.perPagePermissions,
        page: this.currentPagePermissions,
        sortBy: this.sortByPermissions,
        sortDesc: this.isSortDirDescPermissions,

      })
        .then(response => {
          this.options = response.data.map(item => ({ name: item.name }))
          this.totalOptions = response.total
        })
    },

    modalExcluir(item) {
      this.dadosItem.nome = item.nome
      this.dadosItem.uuid = item.uuid
      this.$root.$emit('bv::show::modal', 'modal-confirmacao')
    },
    excluirItem(dados) {
      this.deleteItem(dados)
    },
    salvarItemEditado(dadosItem) {
      this.$root.$emit('bv::hide::modal', 'modal-editar')
      this.update(dadosItem)
    },
  },

}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
